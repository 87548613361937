export const items = [
  {
    title: "dashboard",
    icon: "mdi-view-dashboard-outline",
    group: "fd1",
    to: "/dashboard"
  },
  {
    title: "page_video",
    icon: "mdi-video-outline",
    to: "/page-video"
  },
  {
    title: "payouts",
    icon: "mdi-cash-multiple",
    to: "/payouts"
  },
  {
    title: "affiliate_programs"
  },
  {
    title: "turbobit_net",
    icon: "$turbobit",
    group: "fd1",
    opened: vm => {
      return vm.$store.state.auth.userData.default_active_menu_app === "fd1";
    },
    children: [
      {
        title: "activate",
        icon: "mdi-check",
        visible: vm => {
          return !vm.$store.state.auth.userData.app_users_id.fd1;
        },
        onClick: vm => {
          vm.$store.dispatch("app/showSnackbar", {
            text: vm.$t("app.activation_in_process")
          });

          vm.$store.dispatch("auth/activateProgram", "fd1").then(() => {
            vm.$store.dispatch("app/closeSnackbar");
          });
        }
      },
      {
        title: "earnings",
        icon: "mdi-chart-bar-stacked",
        to: "earnings",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd1;
        }
      },
      {
        title: "statistics",
        group: "fd1/statistics",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd1;
        },
        children: [
          {
            title: "detailed",
            icon: "mdi mdi-chart-box-plus-outline",
            to: "detailed"
          },
          {
            title: "files",
            icon: "mdi mdi-file-chart-outline",
            to: "files"
          },
          {
            title: "sales",
            icon: "mdi-credit-card-check-outline",
            to: "sales"
          },
          {
            title: "sources",
            icon: "mdi-routes",
            to: "sources"
          },
          {
            title: "files_copies",
            icon: "mdi mdi-file-replace-outline",
            to: "files-copies",
            visible: vm => {
              return vm.$store.state.auth.userData.has_file_copies_fd1;
            }
          }
        ]
      },
      {
        title: "conditions",
        icon: "mdi-text-box-check-outline",
        to: "rates"
      },
      {
        title: "gold_partners",
        icon: "mdi-podium-gold",
        to: "gold"
      },

      {
        title: "my_sites",
        icon: "mdi-web",
        to: "sites",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd1;
        }
      },
      {
        title: "videos",
        icon: "mdi mdi-video-outline",
        to: "videos"
      },
      {
        title: "referrals",
        icon: "mdi-account-group-outline",
        to: "referrals",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd1;
        }
      }
    ]
  },
  {
    title: "hitfile_net",
    icon: "$hitfile",
    group: "fd2",
    opened: vm => {
      return vm.$store.state.auth.userData.default_active_menu_app === "fd2";
    },
    children: [
      {
        title: "activate",
        icon: "mdi-check",
        visible: vm => {
          return !vm.$store.state.auth.userData.app_users_id.fd2;
        },
        onClick: vm => {
          vm.$store.dispatch("app/showSnackbar", {
            text: vm.$t("app.activation_in_process")
          });

          vm.$store.dispatch("auth/activateProgram", "fd2").then(() => {
            vm.$store.dispatch("app/closeSnackbar");
          });
        }
      },
      {
        title: "earnings",
        icon: "mdi-chart-bar-stacked",
        to: "earnings",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd2;
        }
      },
      {
        title: "statistics",
        group: "fd2/statistics",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd2;
        },
        children: [
          {
            title: "detailed",
            icon: "mdi mdi-chart-box-plus-outline",
            to: "detailed"
          },
          {
            title: "files",
            icon: "mdi mdi-file-chart-outline",
            to: "files"
          },
          {
            title: "sales",
            icon: "mdi-credit-card-check-outline",
            to: "sales"
          },
          {
            title: "sources",
            icon: "mdi-routes",
            to: "sources"
          },
          {
            title: "files_copies",
            icon: "mdi mdi-file-replace-outline",
            to: "files-copies",
            visible: vm => {
              return vm.$store.state.auth.userData.has_file_copies_fd2;
            }
          }
        ]
      },
      {
        title: "conditions",
        icon: "mdi-text-box-check-outline",
        to: "rates"
      },
      {
        title: "my_sites",
        icon: " mdi-web",
        to: "sites",
        visible: vm => {
          return vm.$store.state.auth.userData.app_users_id.fd2;
        }
      },
      {
        title: "videos",
        icon: "mdi mdi-video-outline",
        to: "videos"
      },
      {
        title: "referrals",
        icon: "mdi-account-group-outline",
        to: "referrals",
        visible: vm => {
          return vm.$store.state.auth.userData.has_fd2_referrals && vm.$store.state.auth.userData.app_users_id.fd2;
        }
      }
    ]
  }
];
